<template>
  <div class="c-menu__social">
    <SocialIcon
      v-if="model.facebookUrl"
      :icon="iconFacebook"
      :url="model.facebookUrl"
    />
    <SocialIcon
      v-if="model.instagramUrl"
      :icon="iconInstagram"
      :url="model.instagramUrl"
    />
    <SocialIcon
      v-if="model.twitterUrl"
      :icon="iconTwitter"
      :url="model.twitterUrl"
    />
    <SocialIcon
      v-if="model.youtubeUrl"
      :icon="iconYoutube"
      :url="model.youtubeUrl"
    />
  </div>
</template>

<script>
import iconFacebook from '@/assets/img/icons/social-facebook.svg'
import iconInstagram from '@/assets/img/icons/social-instagram.svg'
import iconTwitter from '@/assets/img/icons/social-twitter.svg'
import iconYoutube from '@/assets/img/icons/social-youtube.svg'
import SocialIcon from './SocialIcon.vue'

export default {
  components: {
    SocialIcon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconFacebook,
      iconInstagram,
      iconTwitter,
      iconYoutube
    }
  }
}
</script>
