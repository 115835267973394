<template>
  <svg :class="cssClass">
    <use :xlink:href="icon.symbol" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: null
    }
  },
  computed: {
    cssClass() {
      return [
        this.size ? `c-icon-${this.size}` : ''
      ]
    }
  }
}
</script>
