<template>
  <picture>
    <source
      v-if="wide"
      media="(min-width: 1200px)"
      :srcset="scrset(wide, /* add2x */ false)"
    >
    <source
      v-if="desktop"
      media="(min-width: 992px)"
      :srcset="scrset(desktop, /* add2x */ false)"
    >
    <source
      v-if="tablet"
      media="(min-width: 768px)"
      :srcset="scrset(tablet)"
    >
    <img
      :src="resize(mobile)"
      :srcset="scrset(mobile)"
      :alt="alt"
    >
  </picture>
</template>

<script>
import imageResizer from '@/utils/image-resizer'

export default {
  props: {
    src: {
      type: [Object, String],
      required: true
    },
    resizer: {
      type: String,
      required: false,
      default: ''
    },
    mobile: {
      type: Array,
      required: true
    },
    tablet: {
      type: Array,
      required: false,
      default: null
    },
    desktop: {
      type: Array,
      required: false,
      default: null
    },
    wide: {
      type: Array,
      required: false,
      default: null
    },
    options: {
      type: Object,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    resize(sizes) {
      const resizer = imageResizer.Cms
      return resizer
        .resize(this.src, sizes[0], sizes[1], this.options)
    },
    scrset(sizes, add2x = true) {
      const defaultSize = this.resize(sizes)

      if (!add2x) {
        return defaultSize
      }

      const multipliedSize = this.resize([sizes[0] * 2, sizes[1] * 2])
      return `${defaultSize}, ${multipliedSize} 2x`
    }
  }
}
</script>
