const iframeContainer = document.querySelector('.c-iframe-block')
if (iframeContainer) {
  const myIframe = iframeContainer.querySelector('iframe')
  let containerHeight = 0

  const setHeight = (height) => {
    containerHeight = height
    iframeContainer.style.height = `${containerHeight}px`
  }

  window.addEventListener('message', (e) => {
    if (myIframe.contentWindow === e.source && e.data.height) {
      const { height } = e.data
      setHeight(height)
    }
  })
}
