<template>
  <div class="c-product-size-wrapper">
    <div
      v-if="useNavigation"
      ref="size_carousel"
      class="swiper-container c-product-size-slider"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in model.items"
          :key="index"
          class="swiper-slide"
        >
          <div class="size-list-image">
            <Picture
              v-if="item.image && item.image.url"
              :src="item.image"
              :resizer="item.imageResizer.name"
              :mobile="[50,150]"
              :options="options"
              :alt="item.imageAlt"
            />
          </div>
          <div v-if="item.title">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div>
    <ul
      v-else
      class="c-product-size-list u-bare-list u-flex"
    >
      <li
        v-for="(item, index) in model.items"
        :key="index"
      >
        <div class="size-list-image">
          <Picture
            v-if="item.image && item.image.url"
            :src="item.image"
            :resizer="item.imageResizer.name"
            :mobile="[50,150]"
            :options="options"
            :alt="item.imageAlt"
          />
        </div>
        <div v-if="item.title">
          {{ item.title }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Picture from '@/components/Picture.vue'
import { Swiper, Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      carousel: null,
      options: { mode: 'max' }
    }
  },
  computed: {
    useNavigation() {
      return this.model.items.length > 4
    },
    isMobile() {
      return this.$mq == 'mobile'
    }
  },
  mounted() {
    this.carousel = new Swiper(this.$refs.size_carousel, {
      centeredSlides: this.isMobile,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  }
}
</script>
