<template>
  <div class="c-billboard">
    <component
      :is="model.titleIsH1 ? 'h1' : 'h2'"
      v-if="model.title"
      class="c-billboard__heading"
      v-html="model.title"
    />

    <splide
      v-if="model.movingImage"
      :options="options"
    >
      <splide-slide
        v-for="slide in slidesToDuplicate"
        :key="slide"
      >
        <Picture
          v-if="model.movingImage && model.movingImage.url"
          :src="model.movingImage"
          :resizer="model.imageResizer.name"
          :mobile="[767,]"
          :alt="model.imageAlt"
        />
      </splide-slide>

      <div class="splide__progress">
        <div class="splide__progress__bar" />
      </div>
    </splide>

    <splide
      v-if="model.reverseMovingImage"
      :options="reverseOptions"
    >
      <splide-slide
        v-for="slide in slidesToDuplicate"
        :key="slide"
      >
        <Picture
          v-if="model.reverseMovingImage && model.reverseMovingImage.url"
          :src="model.reverseMovingImage"
          :resizer="model.imageResizer.name"
          :mobile="[767,]"
          :alt="model.imageAlt"
        />
      </splide-slide>

      <div class="splide__progress">
        <div class="splide__progress__bar" />
      </div>
    </splide>

    <div class="c-billboard__cta">
      <Link
        v-if="model.link.url"
        :model="model.link"
        class="c-button"
      />
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link.vue'
import Picture from '@/components/Picture.vue'

import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  components: {
    Splide,
    SplideSlide,
    Link,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    slidesToDuplicate() {
      return window.innerWidth >= 1200 ? this.model.slidesPerViewDesktop * 2 : this.model.slidesPerViewMobile * 2
    },
    options() {
      return {
        type: 'loop',
        perPage: this.model.slidesPerViewDesktop,
        gap: '1rem',
        direction: this.model.movingDirection !== 'rightToLeft' ? 'rtl' : 'ltr',
        speed: 35000,
        easing: 'linear',
        autoplay: true,
        interval: 200,
        drag: false,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        breakpoints: {
          // when window width is < 1200px
          1199: {
            perPage: this.model.slidesPerViewMobile
          }
        }
      }
    },
    reverseOptions() {
      return {
        type: 'loop',
        perPage: this.model.slidesPerViewDesktop,
        gap: '1rem',
        direction: this.model.movingDirection === 'rightToLeft' ? 'rtl' : 'ltr',
        speed: 35000,
        easing: 'linear',
        autoplay: true,
        interval: 200,
        drag: false,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        breakpoints: {
          1199: {
            perPage: this.model.slidesPerViewMobile
          }
        }
      }
    }
  }
}
</script>
