import { deferEpiforms } from '@/utils/defer'

export default function formBlock() {
  const forms = document.querySelectorAll('.c-form-block')
  const getCountryCode = () => {
    let countryCode = null
    const hiddenFields = document.querySelectorAll('input[type=hidden]')
    hiddenFields.forEach((field) => {
      if (field.value.startsWith('+')) {
        countryCode = field.value
      }
    })
    return countryCode
  }
  const checkCountryCode = (el) => {
    if (el.autocomplete === 'tel') {
      getCountryCode()
      if (el.parentNode.querySelectorAll('.country-code').length) {
        return
      }
      const countryCodeEl = document.createElement('span')
      countryCodeEl.classList.add('country-code')
      countryCodeEl.innerText = getCountryCode()
      el.before(countryCodeEl)
    }
  }
  const onFocus = (el) => {
    el.parentElement.classList.add('is-focused')
    checkCountryCode(el)
  }
  const onBlur = (el) => {
    el.parentElement.classList.remove('is-focused')
  }
  const checkValue = (el) => {
    if (el.value.trim() !== '') {
      el.parentElement.classList.add('is-filled')
    } else {
      el.value = ''
      el.parentElement.classList.remove('is-filled')
      if (!el.parentNode.querySelectorAll('.country-code').length) {
        return
      }
      el.parentNode.querySelector('.country-code').remove()
    }
  }
  forms.forEach((form) => {
    const inputs = form.querySelectorAll('input[type=text]')
    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        onFocus(input)
      })
      input.addEventListener('blur', () => {
        onBlur(input)
        checkValue(input)
      })
      input.addEventListener('change', () => {
        checkValue(input)
      })
      const { title, autocomplete } = input
      if (title.length) {
        const requiredTextEl = document.createElement('span')
        requiredTextEl.classList.add('required-text')
        requiredTextEl.innerText = title
        input.parentNode.querySelector('label').append(requiredTextEl)
      }
      if (autocomplete === 'tel') {
        input.type = 'tel'
      }
    })
  })
  /* eslint-disable */
  deferEpiforms(() => {
    window.$$epiforms('.EPiServerForms')
      .on('formsSubmitted', (event) => {
        jQuery('.c-form-block h1, .c-form-block h2').hide()
        jQuery('.c-form-block .c-form-block__description').hide()
      })
  })
  /* eslint-enable */
}
