<template>
  <ul class="c-product-nutrition u-bare-list">
    <h3
      v-if="model.texts.title"
      class="c-product-nutrition-title e-label-heading u-font-size-xlarge"
    >
      {{ model.texts.title }}
    </h3>

    <li v-if="isNotEmpty(model.energyKj) || isNotEmpty(model.energyKcal)">
      <div>
        {{ model.texts.energy }}
        <span v-if="isNotEmpty(model.energyKj)">{{ model.energyKj }} kJ</span>
      </div>
      <span v-if="isNotEmpty(model.energyKcal)">{{ model.energyKcal }} kcal</span>
    </li>

    <li v-if="isNotEmpty(model.fat)">
      <span>{{ model.texts.fat }}</span>
      <span>{{ model.fat }} g</span>
    </li>

    <li v-if="isNotEmpty(model.saturatedFat)">
      <span>{{ model.texts.saturatedFat }}</span>
      <span>{{ model.saturatedFat }} g</span>
    </li>

    <li v-if="isNotEmpty(model.carbohydrate)">
      <span>{{ model.texts.carbohydrate }}</span>
      <span>{{ model.carbohydrate }} g</span>
    </li>

    <li v-if="isNotEmpty(model.sugar)">
      <span>{{ model.texts.sugar }}</span>
      <span>{{ model.sugar }} g</span>
    </li>

    <li v-if="isNotEmpty(model.protein)">
      <span>{{ model.texts.protein }}</span>
      <span>{{ model.protein }} g</span>
    </li>

    <li v-if="isNotEmpty(model.salt)">
      <span>{{ model.texts.salt }}</span>
      <span>{{ model.salt }} g</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  methods: {
    isNotEmpty(value) {
      return value && value !== '0'
    }
  }
}
</script>
