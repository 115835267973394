<template>
  <a
    :href="url"
    target="_blank"
    class="c-social-icon"
  >
    <Icon
      :icon="icon"
      :size="24"
    />
  </a>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    icon: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      default: null
    }
  }
}
</script>
