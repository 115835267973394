<template>
  <div class="c-media-overlay">
    <div
      class="c-media-overlay__trigger"
      @click="onOverlayOpened"
    >
      <Icon
        :icon="iconPlay"
        :size="36"
      />
    </div>

    <div
      ref="overlay"
      class="c-media-overlay__container"
      :class="{ 'active': isOverlayActive }"
      @click="onOverlayClosed"
    >
      <div class="c-media-overlay__media-container">
        <div
          v-if="isOverlayActive"
          class="c-media-overlay__media-wrapper"
        >
          <YoutubeVideo
            :video-id="youtubeVideoId"
            class="c-media-overlay__video"
          />
        </div>
      </div>

      <div class="c-media-overlay__close-button">
        <Icon
          :icon="iconClose"
          :size="36"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import YoutubeVideo from '@/components/YoutubeVideo.vue'
import Icon from '@/components/Icon.vue'
import iconPlay from '@/assets/img/icons/play.svg'
import iconClose from '@/assets/img/icons/close.svg'

export default {
  components: {
    YoutubeVideo,
    Icon
  },
  props: {
    youtubeVideoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOverlayActive: false,
      iconPlay,
      iconClose
    }
  },
  methods: {
    onOverlayOpened() {
      this.isOverlayActive = true
      disableBodyScroll(this.$refs.overlay)
    },
    onOverlayClosed() {
      this.isOverlayActive = false
      enableBodyScroll(this.$refs.overlay)
    }
  }
}
</script>
