import store from '../helpers/store'

export default {
  loadYoutubePlayer() {
    if (store.state.isYoutubeLoaded) {
      return
    }
    const youtubeApiTag = document.createElement('script')
    youtubeApiTag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(youtubeApiTag, firstScriptTag)

    window.onYouTubeIframeAPIReady = () => {
      store.state.isYoutubeLoaded = true
    }
  }
}
