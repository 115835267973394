<template>
  <div class="Form__Element">
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="submissionData"
    >
    <div class="c-form-block__checkbox">
      <label
        class="u-flex u-flex-align-center u-font-size-small"
        for="permission-terms-of-use"
        :class="{'accepted': termsAccepted}"
      >
        <input
          id="permission-terms-of-use"
          v-model="termsAccepted"
          name="termsAccepted"
          class="c-checkbox__input"
          type="checkbox"
        >
        <span
          class="c-checkbox__label"
          v-html="model.permissionBlock.termsHtml"
        />
        <span
          v-show="model.errorMessage"
          role="alert"
          aria-live="polite"
          :data-epiforms-linked-name="model.elementName"
          class="Form__Element__ValidationError"
        >
          {{ model.errorMessage }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from '@vue/composition-api'
// eslint-disable next line
import { deferJQuery } from '@/utils/defer'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const termsAccepted = ref(false)
    const submissionData = computed(() => JSON.stringify({
      termsAccepted: termsAccepted.value,
      permissionVersion: props.model.permissionBlock.permissionVersion
    }))

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }

      deferJQuery(() => {
        const validators = {}
        validators[props.model.validatorName] = (_, __, metadata) => {
          const isValid = termsAccepted.value
          return { isValid, message: isValid ? null : metadata.model.message }
        }

        window.jQuery.extend(true, forms, {
          Validators: validators
        })
      })
    })

    return {
      termsAccepted,
      submissionData
    }
  }
}
</script>
