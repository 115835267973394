<template>
  <div class="c-product-slider">
    <component
      :is="model.titleIsH1 ? 'h1' : 'h2'"
      v-if="model.title"
      class="c-product-slider__title"
      v-html="model.title"
    />
    <div
      ref="carousel"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <a
          v-for="(item, index) in model.items"
          :key="index"
          class="swiper-slide"
          :href="item.url"
        >
          <Picture
            v-if="item.image && item.image.url"
            :src="item.image"
            :resizer="model.imageResizer.name"
            :mobile="[250, 350]"
            :wide="[350, 450]"
            :options="options"
            :alt="item.title"
            class="c-product-slider__image"
          />
        </a>
      </div>
      <div class="swiper-pagination" />
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div>

    <h3
      class="c-product-slider__item-title"
      v-html="currentItemTitle"
    />
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination } from 'swiper'
import Circular from '@/utils/swiper-effect-circular'
import Picture from '@/components/Picture.vue'

Swiper.use([Navigation, Pagination, Circular])

export default {
  components: { Picture },
  props: {
    model: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({ mode: 'max' })
    }
  },
  data() {
    return {
      currentItemTitle: ''
    }
  },
  mounted() {
    const flowSettings = {
      init: false,
      effect: 'circular',
      speed: 500,
      initialSlide: 0,
      grabCursor: true,
      loop: false,
      centeredSlides: false,
      slidesPerView: 1,
      circularEffect: {
        radius: 400,
        translateFactor: 2.2,
        itemRotation: 15
      },
      breakpoints: {
        // when window width is >= 1200px
        1200: {
          circularEffect: {
            radius: 400,
            translateFactor: 2.3,
            itemRotation: 16
          }
        },
        1400: {
          circularEffect: {
            radius: 400,
            translateFactor: 2.6,
            itemRotation: 18
          }
        }
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }

    const swiper = new Swiper(this.$refs.carousel, flowSettings)

    swiper.on('init', () => {
      this.setActiveItemTitle(swiper.activeIndex)
    })
    swiper.on('slideChange', () => {
      this.setActiveItemTitle(swiper.activeIndex)
    })

    swiper.init()
  },
  methods: {
    setActiveItemTitle(itemIndex) {
      this.currentItemTitle = this.model.items[itemIndex].title
    }
  }
}
</script>
