export default {
  // Copy of c# code from ImageResizingNetImageResizer.Resize method
  Cms: {
    resize(image, width, height, options) {
      if (!image || !image.url) {
        return null
      }
      let url = ''
      url = image.url
      const qs = {}
      const queryIndex = url.indexOf('?')

      if (queryIndex >= 0) {
        const queryString = url.substring(queryIndex)
        queryString.split('&').forEach((part) => {
          const item = part.split('=')
          qs[item[0]] = decodeURIComponent(item[1])
        })

        url = url.substring(0, queryIndex)
      }

      if (width > 0) {
        qs.width = width
      }

      if (height > 0) {
        qs.height = height
      }

      if (options != null) {
        qs.rmode = options.mode
        // We are not supporting presets for frontend now
        // qs.preset = options.preset
      }

      const queryParams = []
      Object.entries(qs).forEach(([key, value]) => {
        queryParams.push(`${key}=${value}`)
      })

      const queryString = queryParams.join('&')
      return `${url}?${queryString}`
    }
  }
}
