<template>
  <div class="Form__Element">
    <input
      :id="model.elementGuid"
      type="hidden"
      class="FormHidden"
      data-f-type="hidden"
      :name="model.elementName"
      :value="token"
    >
    <recaptcha
      ref="recaptcha"
      :recaptcha-key="model.recaptchaKey"
      @submit="recaptchaSubmit"
    />
    <div
      v-if="model.disclaimer"
      class="Form_Element_RecaptchaDisclaimer u-font-size-small"
      v-html="model.disclaimer"
    />
    <span
      v-show="model.errorMessage"
      role="alert"
      aria-live="polite"
      :data-epiforms-linked-name="model.elementName"
      class="Form__Element__ValidationError"
    >
      {{ model.errorMessage }}
    </span>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { deferJQuery } from '@/utils/defer'
import Recaptcha from '../Recaptcha.vue'

export default {
  components: {
    Recaptcha
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const token = ref('')
    const recaptcha = ref(null)

    const recaptchaSubmit = async (tokenValue) => {
      token.value = tokenValue

      const form = document.getElementById(props.model.formGuid)
      const submitButton = form.querySelector('[type=submit]')
      submitButton.click()
    }

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms

      if (!forms) {
        return
      }

      deferJQuery(() => {
        const validators = {}
        validators[props.model.validatorName] = (_, fieldValue, metadata) => {
          const isValid = !!fieldValue
          if (!isValid && recaptcha.value) {
            recaptcha.value.verify()
          }

          return { isValid, message: isValid ? null : metadata.model.message }
        }

        window.jQuery.extend(true, forms, {
          Validators: validators
        })
      })
    })

    return {
      token,
      recaptcha,
      recaptchaSubmit
    }
  }
}
</script>
