<template>
  <nav class="c-menu">
    <div
      id="nav-content"
      ref="content"
      class="c-menu__content"
    >
      <div class="c-menu__list-wrapper o-content-box u-width-max u-flex u-flex-justify-space-between">
        <div
          v-for="(section, sectionIndex) in model.sections"
          :key="sectionIndex"
          class="c-menu__links"
          :class="{ 'collapsed': !section.expanded }"
        >
          <div class="c-menu__list-heading u-font-bold">
            <Link
              :model="section.title"
              empty-url-tag="span"
            />
            <div
              v-if="section.links.length"
              class="toggle-icon"
              @click="section.expanded = !section.expanded"
            >
              <span v-if="section.expanded">&ndash;</span>
              <span v-else>+</span>
            </div>
          </div>
          <ul
            v-if="section.links.length"
            class="u-bare-list"
          >
            <li
              v-for="(link, linkIndex) in section.links"
              :key="linkIndex"
            >
              <Link :model="link" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="c-menu__footer"
      @wheel="onWheel"
    >
      <div class="o-content-box--side u-width-max">
        <SocialIcons
          :model="model.socialIcons"
          class="u-mt--m u-mb--m"
        />
        <Link
          v-if="model.stickyButton.url"
          :model="model.stickyButton"
          class="c-button c-button--white"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import Link from '@/components/Link.vue'
import SocialIcons from '@/components/navigation/SocialIcons.vue'

export default {
  components: {
    Link,
    SocialIcons
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  methods: {
    onWheel(e) {
      if (e.deltaY && !Number.isNaN(e.deltaY)) {
        this.$refs.content.scrollTop += e.deltaY
      }
    }
  }
}
</script>
