import SimpleParallax from 'simple-parallax-js'

export default function initParallax() {
  const visuals = document.querySelectorAll('.js-parallax-visual')
  const texts = document.querySelectorAll('.js-parallax-text')

  const instance = new SimpleParallax(visuals, {
    scale: 1.3,
    customWrapper: '.js-parallax-wrapper'
  })
  instance.refresh()

  const overlays = new SimpleParallax(texts, {
    scale: 1.25,
    orientation: 'down',
    customWrapper: '.js-parallax-wrapper'
  })
  overlays.refresh()
}
