<template>
  <ul class="u-bare-list">
    <li
      v-for="(link, index) in links"
      :key="index"
    >
      <Link :model="link" />
    </li>
  </ul>
</template>

<script>
import Link from '@/components/Link.vue'

export default {
  components: {
    Link
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>
