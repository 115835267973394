<template>
  <video
    autoplay
    loop
    muted
    playsinline
    :poster="poster"
  >
    <source
      :src="src"
      type="video/mp4"
    >
  </video>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
